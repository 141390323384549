import anime from "animejs";
import { v4 as uuid } from "uuid";

import * as animate from "../../../helpers/animate";
import { elementInfo, isVisible } from "../../../helpers/app";

export const showDialogWithIdentifier = (dialogIdentifier, callback = () => {}, options = {}) => {
  const dialog = document.querySelector(`[data-dialog-identifier="${dialogIdentifier}"]`);
  showDialog(dialog, callback, options);
};

const confirmDialogHtml = `
  <div class="ir-dialog__container" data-controller="components--ir-dialog--base" data-components--ir-dialog--base-auto-close-if-confirmed-value="true">
    <div class="ir-dialog ir-dialog--modal" data-dialog-identifier="confirm-dialog" data-dialog-width="350" data-dialog-modal="true" data-components--ir-dialog--base-target="dialog" data-closable="false">
      <div class='ir-dialog__header'>
        <h2>Please Confirm</h2>
      </div>
      <div class='ir-dialog__body'>
      </div>
      <div class='ir-dialog__footer d-flex'>
        <button class="ir-btn ir-btn--icon-prepend ir-btn--blue me-4" data-action="components--ir-dialog--base#confirmYes" type="button">
          <span class="ir-btn__icon">
            <span class="spinner"><span class="spinner-inner"></span></span>
            <i class="fa fa-check"></i>
          </span>
          <span class="ir-btn__text">
            Okay
          </span>
        </button>
        <button class="ir-btn ir-btn--gray" data-action="components--ir-dialog--base#confirmNo" type="button">
          <span class="ir-btn__text">
            Cancel
          </span>
        </button>
      </div>
    </div>
  </div>
`;

export const confirmDialog = (message, callback = () => {}, options = {}) => {
  let dialogHtml = confirmDialogHtml;

  const identifier = `confirm-dialog-${uuid()}`;

  dialogHtml = dialogHtml.replace('data-dialog-identifier="confirm-dialog"', `data-dialog-identifier="${identifier}"`);

  if (options.confirmBtnColor) {
    dialogHtml = dialogHtml.replace("ir-btn--blue", `ir-btn--${options.confirmBtnColor}`);
  }

  if (options.autoCloseIfConfirmed === false) {
    dialogHtml = dialogHtml.replace(
      `data-components--ir-dialog--base-auto-close-if-confirmed-value="true"`,
      `data-components--ir-dialog--base-auto-close-if-confirmed-value="false"`,
    );
  }

  if (options.headerColor) {
    dialogHtml = dialogHtml.replace(
      "ir-dialog ir-dialog--modal",
      `ir-dialog ir-dialog--modal ir-dialog--solid-header ir-dialog--solid-header--${options.headerColor}`,
    );
  }

  if (options.title) {
    dialogHtml = dialogHtml.replace("Please Confirm", options.title);
  }

  if (options.hideCancelBtn) {
    dialogHtml = dialogHtml.replace("ir-btn--gray", "ir-btn--gray d-none");
  }

  document.body.insertAdjacentHTML("beforeend", dialogHtml);

  const dialog = document.querySelector(`[data-dialog-identifier='${identifier}']`);
  dialog.confirmCallback = (confirmed) => {
    callback(confirmed);
    if (!confirmed || options.autoCloseIfConfirmed !== false) {
      dialog.closest("div").remove();
    }
  };

  if (options.width) {
    dialog.dataset.dialogWidth = options.width;
    dialog.style.width = `${options.width}px`;
  }

  dialog.querySelector(".ir-dialog__body").innerHTML = message;
  showDialog(dialog, () => {}, options);
};

export const alertDialog = (message, callback = () => {}, options = {}) => {
  const defaultOptions = { title: "Alert", hideCancelBtn: true };
  confirmDialog(message, callback, { ...defaultOptions, ...options });
};

export const errorAlertDialog = (message, callback = () => {}, options = {}) => {
  const defaultOptions = { title: "Alert", hideCancelBtn: true, headerColor: "red", confirmBtnColor: "red" };
  confirmDialog(message, callback, { ...defaultOptions, ...options });
};

export const dynamicDialog = ({
  header,
  content,
  footer = "",
  width = 350,
  dialogIdentifier = "dynamic-content-dialog",
  showNearClickPosition = false,
  clickPosition = { x: 0, y: 0 },
}) => {
  const dialog = getDynamicDialog(dialogIdentifier, showNearClickPosition);

  let dialogContentHTML = `
    <div class="ir-dialog__header">
      <h2>${header}</h2>
    </div>
    <div class="ir-dialog__body">
      ${content}
    </div>
  `;
  if (footer) {
    dialogContentHTML += `
      <div class="ir-dialog__footer d-flex">
        ${footer}
      </div>
    `;
  }

  const container = dialog.querySelector(".ir-dialog__container__contents");
  container.innerHTML = dialogContentHTML;

  dialog.dataset.dialogWidth = width;
  dialog.style.width = `${width}px`;

  dialog.style.zIndex = zIndexForDialog(dialog);
  showDialog(dialog, () => {}, { showNearClickPosition, clickPosition });
};

const getDynamicDialog = (dialogIdentifier) => {
  let dialog = getDialogElem(dialogIdentifier);
  if (dialog) return dialog;

  const dialogHTML = `
    <div class="ir-dialog__container" data-controller="components--ir-dialog--base">
      <div class="ir-dialog ir-dialog--solid-header"
        data-dialog-identifier="${dialogIdentifier}"
        data-dialog-width="350"
        data-components--ir-dialog--base-target="dialog"
        data-closable="true">
        <div class="ir-dialog__drag" data-action="mousedown->components--ir-dialog--base#dragMouseDown">
          <i class="fas fa-grip-lines" aria-hidden="true"></i>
        </div>
        <a class="ir-dialog__close" data-action="components--ir-dialog--base#close" href="#">
          &times;
        </a>
        <div class="ir-dialog__container__contents"></div>
      </div>
    </div>
  `;

  document.body.insertAdjacentHTML("beforeend", dialogHTML);

  return getDialogElem(dialogIdentifier);
};

export const getDialogElem = (dialogIdentifier) => {
  return document.querySelector(`[data-dialog-identifier='${dialogIdentifier}']`);
};

export const showDialog = (dialogOrIdentifier, completeCallback = () => {}, options = {}) => {
  const dialog = lookupDialog(dialogOrIdentifier);

  dialog.style.display = "block";
  const dialogInfo = elementInfo(dialog);

  const animateDistance = 25;
  let topPosition;

  if (options.showNearClickPosition) {
    const dialogHeight = dialogInfo.height;
    const dialogWidth = dialogInfo.width;
    const clickPosition = options.clickPosition;
    topPosition = clickPosition.y - dialogHeight / 2;

    const padding = 20;

    // Set the top position and make sure the dialog does not appear off screen on the bottom or top
    const dialogBottomY = topPosition + dialogHeight + padding;
    const windowBottomY = window.innerHeight + window.scrollY;
    const windowTopY = window.scrollY;

    const distanceBeyondBottom = dialogBottomY - windowBottomY;
    const distanceBeyondTop = windowTopY + padding - topPosition;

    if (distanceBeyondBottom > 0) {
      topPosition -= distanceBeyondBottom;
    } else if (distanceBeyondTop > 0) {
      topPosition += distanceBeyondTop;
    }

    // Set the left position and make sure sure the dialog does not appear off screen left or right
    const halfDialogWidth = dialogWidth / 2; // Default dialog CSS positioning is translated -50% of width
    const beyondRight = clickPosition.x + halfDialogWidth - window.innerWidth - padding;
    let leftPosition;
    if (clickPosition.x - halfDialogWidth < padding) {
      leftPosition = padding + halfDialogWidth;
    } else if (beyondRight > 0) {
      leftPosition = window.innerWidth - halfDialogWidth - padding;
    } else {
      leftPosition = clickPosition.x + padding;
    }
    dialog.style.left = `${leftPosition}px`;
  } else {
    const offsetFromTop = 125;
    topPosition = window.scrollY + offsetFromTop;
  }

  const topPositionAnimateStart = topPosition + animateDistance;

  const isModal = dialog.dataset.dialogModal && dialog.dataset.dialogModal.toString() === "true";
  if (isModal) showDialogModalOverlay(dialog);

  anime({
    targets: dialog,
    opacity: 1,
    top: [`${topPositionAnimateStart}px`, `${topPosition}px`],
    easing: "easeOutQuad",
    duration: 350,
    complete: () => {
      completeCallback();
    },
  });
};

export const isDialogVisible = (dialogOrIdentifier) => {
  const dialog = lookupDialog(dialogOrIdentifier);
  if (!dialog) return false;

  return dialog.style.display === "block";
};

export const lookupDialog = (dialogOrIdentifier) => {
  if (typeof dialogOrIdentifier === "string") {
    return document.querySelector(`[data-dialog-identifier="${dialogOrIdentifier}"]`);
  } else {
    return dialogOrIdentifier;
  }
};

export const hideDialog = (dialog, completeCallback = () => {}) => {
  const dialogRect = dialog.getBoundingClientRect();
  const topPosition = window.scrollY + dialogRect.top;
  const animateDistance = 50;
  const topPositionAnimateEnd = topPosition - animateDistance;
  anime({
    targets: dialog,
    opacity: 0,
    top: [`${topPosition}px`, `${topPositionAnimateEnd}px`],
    easing: "easeInQuad",
    duration: 200,
    complete: () => {
      afterAnimatedOut(dialog);
      completeCallback();
    },
  });
  hideDialogModalOverlay(dialog);
};

let hideDialogModalOverlayTimerId;

const showDialogModalOverlay = (dialog) => {
  if (hideDialogModalOverlayTimerId) {
    clearTimeout(hideDialogModalOverlayTimerId);
  }
  const overlay = document.querySelector(".ir-dialog__modal-overlay");
  overlay.dataset.dialogOwnerIdentifier = dialog.dataset.dialogIdentifier;
  animate.show(overlay, { showOpacity: 0.5 });
};

const hideDialogModalOverlay = (dialog) => {
  const overlay = document.querySelector(".ir-dialog__modal-overlay");
  if (overlay.dataset.dialogOwnerIdentifier !== dialog.dataset.dialogIdentifier) return;
  hideDialogModalOverlayTimerId = setTimeout(() => {
    animate.hide(overlay);
  }, 200);
};

const afterAnimatedOut = (dialogDiv) => {
  dialogDiv.style.display = "none";
  resetForm(dialogDiv);
  removeAnyValidationErrors(dialogDiv);
  removeSelectedIndicators(dialogDiv);
};

const resetForm = (dialogDiv) => {
  const forms = dialogDiv.querySelectorAll("form");
  Array.from(forms).forEach((form) => form.reset());
};

const removeAnyValidationErrors = (dialogDiv) => {
  const fieldWithErrorrs = dialogDiv.querySelectorAll(".field_with_errors");
  Array.from(fieldWithErrorrs).forEach((div) => {
    div.classList.remove("field_with_errors");
  });
  const errorDivSelectors = ["#error_explanation", ".ir-alert__container"];
  errorDivSelectors.forEach((errorDivSelector) => {
    const errorDivs = dialogDiv.querySelectorAll(errorDivSelector);
    Array.from(errorDivs).forEach((div) => {
      div.style.display = "none";
    });
  });
};

const removeSelectedIndicators = (dialogDiv) => {
  const selectedElements = dialogDiv.querySelectorAll(".selected");
  Array.from(selectedElements).forEach((element) => {
    element.classList.remove("selected");
  });
};

const defaultDialogZIndex = 100000;
const defaultModalDialogZIndex = defaultDialogZIndex + 1000;

export const zIndexForDialog = (currentDialog) => {
  if (currentDialog.dataset.dialogModal === "true") return defaultModalDialogZIndex;

  const dialogs = Array.from(document.querySelectorAll(".ir-dialog")).filter((e) => isVisible(e));

  const currentHighestZIndex = dialogs.reduce((highestZIndex, dialog) => {
    if (dialog.dataset.dialogModal === "true") return highestZIndex;
    if (dialog === currentDialog) return highestZIndex;

    const zIndex = Number.parseInt(dialog.style.zIndex);
    if (Number.isNaN(zIndex)) return highestZIndex;

    return zIndex > highestZIndex ? zIndex : highestZIndex;
  }, defaultDialogZIndex);

  return currentHighestZIndex + 1;
};
