import DrawInteractionManager from "../../../../pr/map/interaction-managers/draw/roof-planes";
import SelectInteractionManager from "../../../../pr/map/interaction-managers/select/roof-planes";
import SnapInteractionManager from "../../../../pr/map/interaction-managers/snap/roof-planes";
import TranslateInteractionManager from "../../../../pr/map/interaction-managers/translate/roof-planes";
import ModifyInteractionManager from "../../../../pr/map/interaction-managers/modify/roof-planes";
import MeasureInteractionManager from "../../../../da/map/interaction-managers/measure/base";
import RulerInteractionManager from "../../../../da/map/interaction-managers/ruler/base";

import MapBaseController from "./map_base_controller";

import BaseMapModelSynchronizer from "../../../../pr/map/map-model-synchronizers/base";
import RoofPlanesMapManager from "../../../../pr/map/map-managers/roof-planes";

import {
  EDITOR_MODE_DRAW,
  EDITOR_MODE_SELECT,
  EDITOR_MODE_MEASURE,
} from "../../../../da/layout-editor/helpers/toolbar-constants";
import * as toolbarSelectGroup from "../../../../da/layout-editor/helpers/toolbar-select-group";
import { removeMatchingKeys } from "../../../../helpers/local-store";

export default class MapRoofPlanesController extends MapBaseController {
  static targets = ["map", "compass", "saveBtn", "statusItemZoom", "statusItemRotation"];

  connect() {
    super.connect();

    if (this.project.roofPlanes.length === 0) {
      removeMatchingKeys((k) => k.match(/^le--selectGroup--pr.*/));
    }

    this.mapModelSynchronizer = new BaseMapModelSynchronizer(this);

    this.mapManager = new RoofPlanesMapManager(this);
    this.mapManager.add();

    if (this.project.detail.originLatLng === null) {
      const { editorLat: lat, editorLng: lng } = this.project.projectSite;
      this.project.detail.setOriginLatLng({ lat, lng });
    }

    this.setupMapInteractionManagers();

    this.editorMode = toolbarSelectGroup.getState("prRoofPlanesEditorMode", EDITOR_MODE_DRAW);
    this.initializeEditorMode();

    this.populateStatusbar();

    document.addEventListener("keydown", this.handleKeydown);
  }

  disconnect() {
    document.removeEventListener("keydown", this.handleKeydown);
  }

  setupMapInteractionManagers() {
    this.drawInteractionManager = new DrawInteractionManager(this);

    this.selectInteractionManager = new SelectInteractionManager(this);

    this.snapInteractionManager = new SnapInteractionManager(this);
    this.snapInteractionManager.add();

    this.translateInteractionManager = new TranslateInteractionManager(this);

    this.modifyInteractionManager = new ModifyInteractionManager(this);

    this.measureInteractionManager = new MeasureInteractionManager(this);

    this.rulerInteractionManager = new RulerInteractionManager(this);
  }

  initializeEditorMode() {
    switch (this.editorMode) {
      case EDITOR_MODE_DRAW:
        this.startDrawMode();
        break;
      case EDITOR_MODE_MEASURE:
        this.startMeasureMode();
        break;
      case EDITOR_MODE_SELECT:
        this.startSelectMode();
        break;
      default:
        this.startDrawMode();
    }
  }

  handleKeydown = (event) => {
    if (event.key === "Backspace") {
      if (event.target.tagName !== "INPUT") {
        event.preventDefault();
        this.selectInteractionManager.removeSelectedFeatures(event);
      }
    } else if (event.key === "Escape") {
      this.drawInteractionManager.finish();
      this.measureInteractionManager.finish();
    }
  };

  startDrawMode(_event) {
    this.drawInteractionManager.add();
    this.selectInteractionManager.remove();
    this.modifyInteractionManager.remove();
    this.translateInteractionManager.remove();
    this.measureInteractionManager.remove();
  }

  startSelectMode(_event) {
    this.drawInteractionManager.remove();
    this.selectInteractionManager.add();
    this.modifyInteractionManager.remove();
    this.translateInteractionManager.remove();
    this.measureInteractionManager.remove();
  }

  startMeasureMode(_event) {
    this.drawInteractionManager.remove();
    this.selectInteractionManager.remove();
    this.modifyInteractionManager.remove();
    this.translateInteractionManager.remove();
    this.measureInteractionManager.add();
  }
}
