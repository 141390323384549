import { Controller } from "@hotwired/stimulus";
import * as animate from "../../helpers/animate";
import { isVisible } from "../../helpers/app";

// Used when clicking something should cause other thing(s) to show or hide.
// your page should start out with an item(s) wrapped in
//   {style: 'display: none', data: { components__ir_toggle_target: 'toggleable' }}
//   {data: { components__ir_toggle_target: 'toggleable' }}
// each field that should cause those to change their display should have
//   data: { action: 'change->components--ir-toggle#toggle' }
// with text fields or text areas use the input event
//   data: { action: 'input->components--ir-toggle#toggle' }
// The toggle method will flip the visibility status of each target.
export default class extends Controller {
  static targets = ["toggleable"];

  static values = {
    visibleText: { type: String, default: "" },
    hiddenText: { type: String, default: "" },
  };

  toggle(event) {
    event.preventDefault();

    this.toggleableTargets.forEach((t) => {
      if (isVisible(t)) {
        animate.hide(t, { fadeOut: false });
        if (this.offTextValue !== "") {
          event.target.innerHTML = this.hiddenTextValue;
        }
      } else {
        animate.show(t);
        if (this.onTextValue !== "") {
          event.target.innerHTML = this.visibleTextValue;
        }
      }
    });
  }
}
