import { Controller } from "@hotwired/stimulus";
import * as animate from "../../helpers/animate";

const FLORIDA_716_APPROVAL_SMALL_PANEL_THRESHOLD = 67.5;

export default class extends Controller {
  static targets = ["railType", "floridaDeclineTrue", "railsPerRowContainer"];

  railTypeChanged() {
    if (this.data.get("allowed") !== "") return;

    this.#showHideRailsPerRow();
  }

  #showHideRailsPerRow() {
    const panelLength = this.data.get("panelLength");
    const floridaDeclined = this.floridaDeclineTrueTarget.checked;
    const showRailsPerRow =
      floridaDeclined ||
      this.railTypeTarget.value === "XR10" ||
      panelLength > FLORIDA_716_APPROVAL_SMALL_PANEL_THRESHOLD;

    if (showRailsPerRow) {
      animate.show(this.railsPerRowContainerTarget);
    } else {
      animate.hide(this.railsPerRowContainerTarget);
    }
  }
}
