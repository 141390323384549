import BaseMapManager from "./base";

export default class RoofPlanesMapManager extends BaseMapManager {
  add() {
    this.buildRoofPlanesVectorSource();
    this.buildMeasuresVectorSource();
    this.buildRulersVectorSource();

    this.map = this.buildMap({
      layers: [this.tileLayer, this.roofPlanesVectorLayer, this.measuresVectorLayer, this.rulersVectorLayer],
    });

    this.map.on("moveend", this.onMoveEnd);
  }
}
